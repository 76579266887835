body {
  font-size: 18px;
}

/* image grid styles */
.img-grid {
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

/* modal styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.main-bg {
  background-image: url("./assets/main-l.jpeg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#slider {
  margin: auto;
}

@media (max-width: 768px) {
  .main-bg {
    background-image: url("./assets/Webp.net-resizeimage.jpg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 50px;
  }
  /* .c-ta {
    margin-top: 20%;
  } */

  .c-ta {
    margin-top: -80px;
  }

  .landing-head {
    height: 75vh !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #b0940c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #b0940c;
}

.py-2.5 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.card-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
